import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import ReportFilter from './components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import { formatTime, formatTimeWithDays } from '../common/util/formatter';
import TableShimmer from '../common/components/TableShimmer';

const findMissingIntervals = (items, reportType) => {
  const missingIntervals = [];
  const devices = new Set(items.map((item) => item.deviceId));

  devices.forEach((deviceId) => {
    const deviceItems = items.filter((item) => item.deviceId === deviceId).sort((a, b) => new Date(a.fixTime) - new Date(b.fixTime));

    let totalDuration = 0;

    for (let i = 0; i < deviceItems.length - 1; i += 1) {
      const diff = new Date(deviceItems[i + 1].fixTime) - new Date(deviceItems[i].fixTime);
      if (diff > 30 * 60 * 1000) {
        totalDuration += diff;
        if (reportType === 'Detallado') {
          missingIntervals.push({
            deviceId,
            start: deviceItems[i].fixTime,
            end: deviceItems[i + 1].fixTime,
            duration: diff,
          });
        }
      }
    }

    if (reportType === 'Resumido') {
      missingIntervals.push({
        deviceId,
        start: null,
        end: null,
        duration: totalDuration,
      });
    }
  });

  return missingIntervals;
};

const MissingInformationReport = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const devices = useSelector((state) => state.devices.items);
  usePositionAttributes(t);
  const [missingIntervals, setMissingIntervals] = useState([]);
  const [reportType, setReportType] = useState('Detallado');
  const [loading, setLoading] = useState(false);

  // Limpia missingIntervals cada vez que cambia reportType
  useEffect(() => {
    setMissingIntervals([]);
  }, [reportType]);

  const handleSubmit = useCatch(async ({ deviceIds, groupIds, from, to }) => {
    setLoading(true);
    const query = new URLSearchParams({ from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    groupIds.forEach((groupId) => query.append('groupId', groupId));
    const response = await fetch(`/api/reports/route?${query.toString()}`, {
      headers: { Accept: 'application/json' },
    });
    if (response.ok) {
      const items = await response.json();
      const missing = findMissingIntervals(items, reportType);
      setMissingIntervals(missing); // Actualizamos el estado con el nuevo conjunto de intervalos perdidos
    } else {
      throw Error(await response.text());
    }
    setLoading(false);
  });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportRoute']}>
      <div className={classes.container}>
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit} showOnly multiDevice includeGroups>
              <div className={classes.filterItem}>
                <FormControl fullWidth>
                  <InputLabel>{t('reportType')}</InputLabel>
                  <Select
                    label={t('reportType')}
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                  >
                    <MenuItem value="Detallado">{t('reportDetailed')}</MenuItem>
                    <MenuItem value="Resumido">{t('reportSummary')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </ReportFilter>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('reportDevice')}</TableCell>
                <TableCell>{t('reportStartDate')}</TableCell>
                <TableCell>{t('reportEndDate')}</TableCell>
                <TableCell>{t('reportDuration')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? missingIntervals.map((interval) => {
                const deviceName = devices[interval.deviceId]?.name || interval.deviceId;
                return (
                  <TableRow key={interval.deviceId}>
                    <TableCell>{deviceName}</TableCell>
                    <TableCell>{interval.start ? formatTime(interval.start) : '-'}</TableCell>
                    <TableCell>{interval.end ? formatTime(interval.end) : '-'}</TableCell>
                    <TableCell>{formatTimeWithDays(interval.duration, t)}</TableCell>
                  </TableRow>
                );
              }) : (<TableShimmer columns={4} />)}
            </TableBody>
          </Table>
        </div>
      </div>
    </PageLayout>
  );
};

export default MissingInformationReport;

import React from 'react';
import {
  List, ListItemButton, ListItemIcon, ListItemText, Divider,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import TimelineIcon from '@mui/icons-material/Timeline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import BarChartIcon from '@mui/icons-material/BarChart';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import FlightIcon from '@mui/icons-material/Flight';
import RouteIcon from '@mui/icons-material/Route';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useAdministrator, useRestriction } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const ReportsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const admin = useAdministrator();
  const readonly = useRestriction('readonly');

  return (
    <>
      <List>
        <MenuItem
          title={t('reportRoute')}
          link="/reports/route"
          icon={<TimelineIcon />}
          selected={location.pathname === '/reports/route'}
        />
        <MenuItem
          title={t('reportStops')}
          link="/reports/stop"
          icon={<PauseCircleFilledIcon />}
          selected={location.pathname === '/reports/stop'}
        />
        <MenuItem
          title={t('reportTrips')}
          link="/reports/trip"
          icon={<PlayCircleFilledIcon />}
          selected={location.pathname === '/reports/trip'}
        />
        <MenuItem
          title={t('reportSummary')}
          link="/reports/summary"
          icon={<FormatListBulletedIcon />}
          selected={location.pathname === '/reports/summary'}
        />
        <MenuItem
          title={t('reportChart')}
          link="/reports/chart"
          icon={<TrendingUpIcon />}
          selected={location.pathname === '/reports/chart'}
        />
        <MenuItem
          title={t('reportEvents')}
          link="/reports/event"
          icon={<NotificationsActiveIcon />}
          selected={location.pathname === '/reports/event'}
        />
        <MenuItem
          title={t('reportReplay')}
          link="/replay"
          icon={<RouteIcon />}
        />
        <MenuItem
          title="Velocidad"
          link="/reports/speed"
          icon={<SpeedRoundedIcon />}
          selected={location.pathname === '/reports/speed'}
        />
        <MenuItem
          title="Horas de vuelo"
          link="/reports/flighthours"
          icon={<FlightIcon />}
          selected={location.pathname === '/reports/flighthours'}
        />
        {(admin || !readonly) && (
          <MenuItem
            title={t('reportCombined')}
            link="/reports/combined"
            icon={<StarIcon />}
            selected={location.pathname === '/reports/combined'}
          />
        )}
      </List>
      <Divider />
      <List>
        {(admin || !readonly) && (
          <MenuItem
            title={t('reportScheduled')}
            link="/reports/scheduled"
            icon={<EventRepeatIcon />}
          />
        )}
        {(admin || !readonly) && (
          <MenuItem
            title={t('statisticsTitle')}
            link="/reports/statistics"
            icon={<BarChartIcon />}
            selected={location.pathname === '/reports/statistics'}
          />
        )}
        {(admin || !readonly) && (
          <MenuItem
            title="Información faltante"
            link="/reports/missing"
            icon={<BusAlertIcon />}
          />
        )}
      </List>
    </>
  );
};

export default ReportsMenu;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton, Tooltip, Avatar, ListItemAvatar, ListItemText, ListItemButton,
} from '@mui/material';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ErrorIcon from '@mui/icons-material/Error';
import SpeedIcon from '@mui/icons-material/Speed';
import moment from 'moment';
import { devicesActions } from '../store';
import {
  formatAlarm, formatBoolean, formatPercentage, formatStatus, getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { useAttributePreference } from '../common/util/preferences';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const geofences = useSelector((state) => state.geofences.items);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');

  const formatProperty = (key) => {
    if (key === 'geofenceIds') {
      const geofenceIds = item[key] || [];
      return geofenceIds
        .filter((id) => geofences.hasOwnProperty(id))
        .map((id) => geofences[id].name)
        .join(', ');
    }
    return item[key];
  };

  const secondaryText = () => {
    let status;
    if (item.status === 'online' || !item.lastUpdate) {
      status = formatStatus(item.status, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
    }
    return (
      <>
        {deviceSecondary && item[deviceSecondary] && `${formatProperty(deviceSecondary)} • `}
        <span className={classes[getStatusColor(item.status)]}>{status}</span>
      </>
    );
  };

  const [iconColor, setIconColor] = useState('#9e9e9e');
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [isTimeElapsed, setIsTimeElapsed] = useState(moment().unix());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIconColor((prevColor) => (prevColor === '#9e9e9e' ? '#f44336' : '#9e9e9e'));
      setCurrentTime(moment().unix());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (position && position.fixTime) {
      const fixTimeUnix = moment(position.fixTime).unix(); // Convierte position.fixTime a marca de tiempo Unix
      const timeDifference = (currentTime - fixTimeUnix) / 60; // Calcular diferencia en minutos
      setIsTimeElapsed(timeDifference >= 30); // Verificar si han pasado 30 minutos
    }
  }, [position, currentTime]);

  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.selectId(item.id))}
        disabled={!admin && item.disabled}
      >
        <ListItemAvatar>
          <Avatar>
            <img className={classes.icon} src={mapIcons[mapIconKey(item.category)]} alt="" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={formatProperty(devicePrimary)}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {position && (
          <>
            {position.attributes.speedLimit > 10 && position.speed > position.attributes.speedLimit && !isTimeElapsed ? (
              <Tooltip title="Exceso de velocidad: Sí">
                {!isTimeElapsed ? (
                  <IconButton size="small">
                    <SpeedIcon width={20} height={20} style={{ color: iconColor }} />
                  </IconButton>
                ) : (
                  <IconButton size="small">
                    <SpeedIcon width={20} height={20} style={{ color: '#9e9e9e' }} />
                  </IconButton>
                )}
              </Tooltip>
            ) : (
              <Tooltip title="Exceso de velocidad: No">
                <IconButton size="small">
                  <SpeedIcon width={20} height={20} style={{ color: '#9e9e9e' }} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('alarm') && !isTimeElapsed && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="small">
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small">
                  {(position.attributes.ignition && !isTimeElapsed) ? (
                    <EngineIcon width={20} height={20} className={classes.positive} />
                  ) : (
                    <EngineIcon width={20} height={20} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('batteryLevel') && !isTimeElapsed && (
              <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                <IconButton size="small">
                  {position.attributes.batteryLevel > 70 ? (
                    position.attributes.charge ? (
                      <BatteryChargingFullIcon fontSize="small" className={classes.positive} />
                    ) : (
                      <BatteryFullIcon fontSize="small" className={classes.positive} />
                    )
                  ) : position.attributes.batteryLevel > 30 ? (
                    position.attributes.charge ? (
                      <BatteryCharging60Icon fontSize="small" className={classes.medium} />
                    ) : (
                      <Battery60Icon fontSize="small" className={classes.medium} />
                    )
                  ) : (
                    position.attributes.charge ? (
                      <BatteryCharging20Icon fontSize="small" className={classes.negative} />
                    ) : (
                      <Battery20Icon fontSize="small" className={classes.negative} />
                    )
                  )}
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;

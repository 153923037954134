import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {
  formatDistance,
  formatHours,
  formatVolume,
  formatTime,
} from '../common/util/formatter';
import ReportFilter from './components/ReportFilter';
import {
  useAttributePreference,
  usePreference,
} from '../common/util/preferences';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ColumnSelect from './components/ColumnSelect';
import usePersistedState from '../common/util/usePersistedState';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import MapPositions from '../map/MapPositions';
import MapView from '../map/core/MapView';
import MapCamera from '../map/MapCamera';
import AddressValue from '../common/components/AddressValue';
import TableShimmer from '../common/components/TableShimmer';
import MapGeofence from '../map/MapGeofence';
import scheduleReport from './common/scheduleReport';

const columnsArray = [
  ['startTime', 'reportStartTime'],
  ['startOdometer', 'positionOdometer'],
  ['address', 'positionAddress'],
  ['endTime', 'reportEndTime'],
  ['duration', 'reportDuration'],
  ['engineHours', 'reportEngineHours'],
  ['spentFuel', 'reportSpentFuel'],
];
const columnsMap = new Map(columnsArray);

const StopReportPage = () => {
  const navigate = useNavigate();
  const classes = useReportStyles();
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [columns, setColumns] = usePersistedState('stopColumns', [
    'startTime',
    'endTime',
    'duration',
    'address',
  ]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [stops, setStops] = useState([]);
  const [stopDistances, setStopDistances] = useState([]);

  const calculateSimpleDistance = (lat1, lon1, lat2, lon2) => {
    const dLat = Math.abs(lat1 - lat2);
    const dLon = Math.abs(lon1 - lon2);
    return Math.sqrt(dLat * dLat + dLon * dLon);
  };

  const handleSubmit = useCatch(
    async ({ deviceId, from, to, type }) => {
      const query = new URLSearchParams({ deviceId, from, to });
      if (type === 'export') {
        window.location.assign(`/api/reports/stops/xlsx?${query.toString()}`);
      } else if (type === 'mail') {
        const response = await fetch(
          `/api/reports/stops/mail?${query.toString()}`,
        );
        if (!response.ok) {
          throw Error(await response.text());
        }
      } else {
        setLoading(true);
        try {
          const response = await fetch(
            `/api/reports/stops?${query.toString()}`,
            {
              headers: { Accept: 'application/json' },
            },
          );
          if (response.ok) {
            const stopItems = await response.json();
            setItems(stopItems);
            const newStopDistances = stopItems.map((stop, index) => {
              for (let i = 0; i < stopItems.length; i += 1) {
                if (i !== index) {
                  const otherStop = stopItems[i];
                  if (calculateSimpleDistance(stop.latitude, stop.longitude, otherStop.latitude, otherStop.longitude) < 0.000450) {
                    return true;
                  }
                }
              }
              return false;
            });
            setStopDistances(newStopDistances);
            setStops(
              stopItems.map((item) => ({
                deviceId: item.deviceId,
                fixTime: item.startTime,
                latitude: item.latitude,
                longitude: item.longitude,
                duration: item.duration,
              })),
            );
          } else {
            throw Error(await response.text());
          }
        } finally {
          setLoading(false);
        }
      }
    },
  );

  const handleSchedule = useCatch(
    async (deviceIds, groupIds, report) => {
      report.type = 'stops';
      const error = await scheduleReport(deviceIds, groupIds, report);
      if (error) {
        throw Error(error);
      } else {
        navigate('/reports/scheduled');
      }
    },
  );

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'startOdometer':
        return formatDistance(item[key], distanceUnit, t);
      case 'duration':
        return formatHours(item[key]);
      case 'engineHours':
        return formatHours(item[key]);
      case 'spentFuel':
        return formatVolume(item[key], volumeUnit, t);
      case 'address':
        return (
          <AddressValue
            latitude={item.latitude}
            longitude={item.longitude}
            originalAddress={item[key]}
          />
        );
      default:
        return item[key];
    }
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportStops']}>
      <div className={classes.container}>
        {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              <MapPositions
                positions={stops}
                titleField="duration"
              />
            </MapView>
            <MapCamera
              latitude={selectedItem.latitude}
              longitude={selectedItem.longitude}
              shouldCenterMap
            />
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit} handleSchedule={handleSchedule}>
              <ColumnSelect
                columns={columns}
                setColumns={setColumns}
                columnsArray={columnsArray}
              />
            </ReportFilter>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnAction} />
                {columns.map((key) => (
                  <TableCell key={key}>{t(columnsMap.get(key))}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                items.map((item, index) => (
                  <TableRow key={item.positionId} style={stopDistances[index] ? { backgroundColor: '#eeeeee' } : {}}>
                    <TableCell className={classes.columnAction} padding="none">
                      {selectedItem === item ? (
                        <IconButton
                          size="small"
                          onClick={() => setSelectedItem(null)}
                        >
                          <GpsFixedIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => setSelectedItem(item)}
                        >
                          <LocationSearchingIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                    {columns.map((key) => (
                      <TableCell key={key}>{formatValue(item, key)}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableShimmer columns={columns.length + 1} startAction />
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </PageLayout>
  );
};

export default StopReportPage;

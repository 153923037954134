import { useEffect, useRef } from 'react';
import { map } from '../core/MapView';
import './extrabutton.css';

const statusClass = (status) => `maplibregl-ctrl-icon maplibre-ctrl-button maplibre-ctrl-button-${status}`;

class ExtraButtonsControl {
  constructor(eventHandler) {
    this.eventHandler = eventHandler;
  }

  onAdd() {
    const button = document.createElement('button');
    button.className = statusClass(false); // Puedes establecer el estado inicial aquí
    button.type = 'button';
    button.onclick = () => this.eventHandler(this);

    const container = document.createElement('div');
    container.className = 'maplibregl-ctrl-group maplibregl-ctrl';
    container.appendChild(button);

    this.container = container;

    return container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
  }

  setEnabled(enabled) {
    const button = this.container.querySelector('button');
    if (button) {
      button.className = statusClass(enabled ? 'on' : 'off');
    }
  }
}

const MapExtraButtons = ({ enabled, onToggle }) => {
  const toggleMapCluster = () => {
    onToggle(); // Llama a la función proporcionada en las props
  };

  const controlRef = useRef(null);

  useEffect(() => {
    const control = new ExtraButtonsControl(toggleMapCluster);
    controlRef.current = control;
    map.addControl(control);
    return () => map.removeControl(control);
  }, []);

  useEffect(() => {
    const control = controlRef.current;
    if (control) {
      control.setEnabled(enabled);
    }
  }, [enabled]);

  return null;
};

export default MapExtraButtons;

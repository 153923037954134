import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '240px',
    maxHeight: '240px',
    width: '100%',
    height: '100%',
    margin: '0 auto',
  },
  svgContent: {
    width: '100%',
    height: '100%',
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();
  const [svgContent, setSvgContent] = useState('');

  const { attributes } = useSelector((state) => state.session.server || {});
  const imageFile = attributes ? attributes.logo : undefined;

  // Deshabilitamos la regla `prefer-destructuring` para esta línea
  // eslint-disable-next-line prefer-destructuring
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  const logoPath = subdomain === 'www' || subdomain === '' || hostname.split('.').length === 1
    ? '/static/media/logos/default.svg'
    : `/static/media/logos/${subdomain}.svg`;

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(logoPath);
        if (!response.ok) throw new Error('Network response was not ok');
        const text = await response.text();
        setSvgContent(text);
      } catch (error) {
        const response = await fetch('/static/media/logos/default.svg');
        const text = await response.text();
        setSvgContent(text);
      }
    };
    fetchSvg();
  }, [logoPath]);

  useEffect(() => {
    if (svgContent) {
      const coloredSvg = svgContent.replace('<svg', `<svg style="color: ${color}; width: 100%; height: 100%;"`);
      setSvgContent(coloredSvg);
    }
  }, [svgContent, color]);

  if (imageFile) {
    return (
      <img className={classes.imageContainer} src={imageFile} alt="Logo" />
    );
  }

  return (
    <div
      className={classes.imageContainer}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default LogoImage;

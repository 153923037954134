import {
  amber, grey, blue, green, red, common,
} from '@mui/material/colors';

export default (server) => {
  const colors = {
    white: common.white,
    background: grey[50],
    primary: server?.attributes?.colorPrimary || blue[900],
    secondary: server?.attributes?.colorSecondary || green[800],
    positive: green[500],
    medium: amber[700],
    negative: red[500],
    neutral: grey[500],
    geometry: '#3bb2d0',
  };

  return {
    background: {
      default: colors.background,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    colors,
    action: { // Integrated action configurations
      hover: 'rgba(0, 0, 0, 0.15)',
      hoverOpacity: 0.15,
      selectedOpacity: 0.25,
      disabledOpacity: 0.50,
      focusOpacity: 0.25,
      activatedOpacity: 0.25,
    },
  };
};

import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import { SwitcherControl } from '../switcher/switcher';
import { useAttributePreference, usePreference } from '../../common/util/preferences';
import usePersistedState, { savePersistedState } from '../../common/util/usePersistedState';
import { mapImages } from './preloadImages';
import useMapStyles from './useMapStyles';
import './downloadbutton.css';

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';
element.style.boxSizing = 'initial';

export const map = new maplibregl.Map({
  container: element,
  attributionControl: false,
});

let ready = false;
const readyListeners = new Set();

const addReadyListener = (listener) => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = (listener) => {
  readyListeners.delete(listener);
};

const updateReadyValue = (value) => {
  ready = value;
  readyListeners.forEach((listener) => listener(value));
};

const handleDownloadMapImage = () => {
  map.once('render', () => {
    const canvas = map.getCanvas();
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    // Obtener la fecha actual
    const currentDate = new Date();

    // Crear el nombre del archivo utilizando el formato deseado
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const fileName = `Mapa Pampasat (${year}-${month}-${day}).png`;

    link.href = image;
    link.download = fileName;
    link.click();
  });
  map.triggerRepaint();
};

const initMap = async () => {
  if (ready) return;
  if (!map.hasImage('background')) {
    Object.entries(mapImages).forEach(([key, value]) => {
      map.addImage(key, value, {
        pixelRatio: window.devicePixelRatio,
      });
    });
  }
  updateReadyValue(true);
};

map.addControl(new maplibregl.NavigationControl());

const switcher = new SwitcherControl(
  () => updateReadyValue(false),
  (styleId) => savePersistedState('selectedMapStyle', styleId),
  () => {
    map.once('styledata', () => {
      const waiting = () => {
        if (!map.loaded()) {
          setTimeout(waiting, 33);
        } else {
          initMap();
        }
      };
      waiting();
    });
  },
);

map.addControl(switcher);

let customButtonContainer;

const createCustomButton = () => {
  if (!customButtonContainer) {
    // Crea el botón personalizado
    const customButton = document.createElement('button');
    customButton.type = 'button';
    customButton.className = 'maplibre-ctrl-button-custom maplibre-ctrl-button';

    // Agrega el evento de clic al botón personalizado
    customButton.addEventListener('click', handleDownloadMapImage);

    // Crea el contenedor del botón personalizado
    customButtonContainer = document.createElement('div');
    customButtonContainer.className = 'maplibregl-ctrl-group maplibregl-ctrl';
    customButtonContainer.appendChild(customButton);
  }

  return customButtonContainer;
};

const MapView = ({ children }) => {
  const containerEl = useRef(null);

  const [mapReady, setMapReady] = useState(false);

  const mapStyles = useMapStyles();
  const activeMapStyles = useAttributePreference('activeMapStyles', 'locationIqStreets,osm,carto');
  const [defaultMapStyle] = usePersistedState('selectedMapStyle', usePreference('map', 'locationIqStreets'));
  const mapboxAccessToken = useAttributePreference('mapboxAccessToken');
  const maxZoom = useAttributePreference('web.maxZoom');

  useEffect(() => {
    if (maxZoom) {
      map.setMaxZoom(maxZoom);
    }
  }, [maxZoom]);

  useEffect(() => {
    maplibregl.accessToken = mapboxAccessToken;
  }, [mapboxAccessToken]);

  useEffect(() => {
    const filteredStyles = mapStyles.filter((s) => s.available && activeMapStyles.includes(s.id));
    const styles = filteredStyles.length ? filteredStyles : mapStyles.filter((s) => s.id === 'osm');
    switcher.updateStyles(styles, defaultMapStyle);
  }, [mapStyles, defaultMapStyle]);

  useEffect(() => {
    const listener = (ready) => setMapReady(ready);
    addReadyListener(listener);
    return () => {
      removeReadyListener(listener);
    };
  }, []);

  useLayoutEffect(() => {
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    map.resize();
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  useEffect(() => {
    let customButtonContainer = null;

    const addCustomButton = () => {
      if (!customButtonContainer) {
        customButtonContainer = createCustomButton();
        map.addControl({
          onAdd: () => customButtonContainer,
        });
        map.resize();
      }
    };

    const removeCustomButton = () => {
      if (customButtonContainer) {
        map.removeControl(customButtonContainer);
        customButtonContainer = null;
      }
    };

    addCustomButton();

    return () => {
      removeCustomButton();
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerEl}>
      {mapReady && children}
    </div>
  );
};

export default MapView;

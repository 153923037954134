import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, Select, InputLabel, FormControl,
} from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import moment from 'moment';
import ReportFilter from './components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapPositions from '../map/MapPositions';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import MapCamera from '../map/MapCamera';
import MapGeofence from '../map/MapGeofence';

const SpeedExcessReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);

  const [shouldCenterMap, setShouldCenterMap] = useState(false); // Añadido para controlar el centrado del mapa
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [maxSpeed, setMaxSpeed] = useState(120);
  const [speedSetting, setSpeedSetting] = useState('numeric'); // 'numeric' or 'legal'
  const [maxTime, setMaxTime] = useState(60);

  useEffect(() => {
    if (shouldCenterMap) {
      setTimeout(() => {
        setShouldCenterMap(false); // Desactiva el centrado después de un breve período
      }, 1000); // Ajusta este tiempo según sea necesario
    }
  }, [shouldCenterMap]);

  const handleSubmit = useCatch(async ({ deviceIds, groupIds, from, to, type }) => {
    const query = new URLSearchParams({ from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    groupIds.forEach((groupId) => query.append('groupId', groupId));
    if (type === 'export') {
      window.location.assign(`/api/reports/route/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reports/route/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/route?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          let data = await response.json();

          const { groups, group } = data.reduce((acc, item) => {
            let maxSpeedInKnots = maxSpeed / 1.852;
            if (speedSetting === 'legal' && item.attributes && item.attributes.speedLimit) {
              maxSpeedInKnots = item.attributes.speedLimit;
            }
            if (item.speed > maxSpeedInKnots) {
              acc.group.push(item);
            } else if (acc.group.length > 0) {
              acc.groups.push(acc.group);
              acc.group = [];
            }
            return acc;
          }, { groups: [], group: [] });

          if (group.length > 0) {
            groups.push(group);
          }

          let groupId = 0;
          data = groups.flatMap((group) => {
            const firstTime = new Date(group[0].fixTime).getTime();
            const lastTime = new Date(group[group.length - 1].fixTime).getTime();
            if (lastTime - firstTime > maxTime * 1000) {
              const averageSpeed = group.reduce((sum, item) => sum + item.speed, 0) / group.length;
              const averageLegalSpeed = speedSetting === 'legal' ? group.reduce((sum, item) => sum + (item.attributes?.speedLimit || 0), 0) / group.length : 0;
              if (speedSetting !== 'legal' || (speedSetting === 'legal' && averageLegalSpeed > 0)) {
                groupId += 1;
                return group.map((item) => ({ ...item, groupId, firstTime, lastTime, averageSpeed, averageLegalSpeed }));
              }
            }
            return [];
          });
          setItems(data);
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    setItems([]); // Reinicia el array items cada vez que cambia speedSetting.
  }, [speedSetting]);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportSpeedExcess']}>
      <div className={classes.container}>
        {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              {[...new Set(items.filter((it) => it.groupId === selectedItem.groupId).map((it) => it.deviceId))].map((deviceId) => {
                const positions = items.filter((position) => position.deviceId === deviceId && position.groupId === selectedItem.groupId);
                return (
                  <Fragment key={deviceId}>
                    <MapRoutePath positions={positions} />
                    <MapPositions positions={items.filter((it) => it.groupId === selectedItem.groupId)} titleField="speed" />
                    <MapCamera positions={positions} shouldCenterMap={shouldCenterMap} />
                  </Fragment>
                );
              })}
            </MapView>
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit} showOnly multiDevice includeGroups>
              <FormControl variant="outlined" className={classes.filterItem}>
                <InputLabel id="speed-setting-label">Tipo de Velocidad</InputLabel>
                <Select
                  labelId="speed-setting-label"
                  value={speedSetting}
                  onChange={(event) => setSpeedSetting(event.target.value)}
                  label="Tipo de Velocidad"
                >
                  <MenuItem value="numeric">Numérica</MenuItem>
                  <MenuItem value="legal">Máxima Legal</MenuItem>
                </Select>
              </FormControl>
              {speedSetting === 'numeric' && (
                <TextField
                  label="Velocidad (km/h)"
                  value={maxSpeed}
                  onChange={(event) => setMaxSpeed(event.target.value)}
                  variant="outlined"
                  className={classes.filterItem}
                />
              )}
              <TextField
                label="Tiempo (segundos)"
                value={maxTime}
                onChange={(event) => setMaxTime(event.target.value)}
                variant="outlined"
                className={classes.filterItem}
              />
            </ReportFilter>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnAction} />
                <TableCell>{t('sharedDevice')}</TableCell>
                <TableCell>{t('reportStartTime')}</TableCell>
                <TableCell>{t('reportEndTime')}</TableCell>
                <TableCell>Duración</TableCell>
                <TableCell>{t('reportAverageSpeed')}</TableCell>
                {speedSetting === 'legal' && <TableCell>Velocidad legal media</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? [...new Set(items.map((it) => it.groupId))].map((groupId) => {
                const groupItems = items.filter((it) => it.groupId === groupId);
                const firstItem = groupItems[0];

                const duration = moment(firstItem.lastTime).from(moment(firstItem.firstTime), true);
                return (
                  <TableRow key={groupId}>
                    <TableCell padding="checkbox">
                      {selectedItem && selectedItem.groupId === groupId ? (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedItem(null);
                            setShouldCenterMap(true); // Añadido para centrar el mapa al hacer clic en el botón
                          }}
                        >
                          <GpsFixedIcon
                            fontSize="small"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedItem(firstItem);
                            setShouldCenterMap(true); // Añadido para centrar el mapa al hacer clic en el botón
                          }}
                        >
                          <LocationSearchingIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>{devices[firstItem.deviceId].name}</TableCell>
                    <TableCell>{new Date(firstItem.firstTime).toLocaleString()}</TableCell>
                    <TableCell>{new Date(firstItem.lastTime).toLocaleString()}</TableCell>
                    <TableCell>{duration}</TableCell>
                    <TableCell>{(firstItem.averageSpeed * 1.852).toFixed(1)}</TableCell>
                    {speedSetting === 'legal' && <TableCell>{(firstItem.averageLegalSpeed * 1.852).toFixed(1)}</TableCell>}
                  </TableRow>
                );
              }) : (
                <TableShimmer count={10} columns={speedSetting === 'legal' ? 7 : 6} />
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </PageLayout>
  );
};

export default SpeedExcessReportPage;

import React, { useRef } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useTranslation } from './LocalizationProvider';

const DoubleButton = ({
  fullWidth,
  variant,
  color,
  disabled,
  onClick,
  onClick2,
}) => {
  const anchorRef = useRef();
  const t = useTranslation();

  return (
    <ButtonGroup fullWidth={fullWidth} variant={variant} color={color} ref={anchorRef}>
      <Button disabled={disabled} onClick={onClick}>
        <Typography variant="button" noWrap>{t('reportShow')}</Typography>
      </Button>
      <Button fullWidth={false} size="medium" onClick={onClick2}>
        <ImageIcon />
      </Button>
    </ButtonGroup>
  );
};

export default DoubleButton;

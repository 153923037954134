import { useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import { map } from './core/MapView';

const MapCamera = ({
  latitude, longitude, positions, coordinates, shouldCenterMap,
}) => {
  useEffect(() => {
    if (shouldCenterMap) { // Solo ejecuta la lógica de centrado si shouldCenterMap es true
      if (coordinates || positions) {
        if (!coordinates) {
          coordinates = positions.map((item) => [item.longitude, item.latitude]);
        }
        if (coordinates.length) {
          const bounds = coordinates.reduce((bounds, item) => bounds.extend(item), new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
          const canvas = map.getCanvas();
          map.fitBounds(bounds, {
            padding: Math.min(canvas.width, canvas.height) * 0.1,
            duration: 0,
          });
        }
      } else {
        map.jumpTo({
          center: [longitude, latitude],
          zoom: Math.max(map.getZoom(), 10),
        });
      }
    }
  }, [latitude, longitude, positions, coordinates, shouldCenterMap]); // Añade shouldCenterMap a la lista de dependencias

  return null;
};

export default MapCamera;

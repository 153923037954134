import FileSaver from 'file-saver';
import { useCurrentPng } from 'recharts-to-png';
import React, { useCallback, useState, useEffect } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import {
  CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import ReportFilter from './components/ReportFilter';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { useCatch } from '../reactHelper';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import {
  altitudeFromMeters, distanceFromMeters, speedFromKnots, volumeFromLiters,
} from '../common/util/converter';
import useReportStyles from './common/useReportStyles';

const ChartReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);

  const distanceUnit = useAttributePreference('distanceUnit');
  const altitudeUnit = useAttributePreference('altitudeUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [items, setItems] = useState([]);
  const [type, setType] = useState('speed');
  const [xAxisInterval, setXAxisInterval] = useState(0);

  const handleSubmit = useCatch(async ({ deviceId, from, to }) => {
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/reports/route?${query.toString()}`, {
      headers: { Accept: 'application/json' },
    });
    if (response.ok) {
      const positions = await response.json();
      const formattedPositions = positions.map((position) => {
        const data = { ...position, ...position.attributes };
        const formatted = {};
        formatted.fixTime = formatTime(position.fixTime, 'minutes', hours12);
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (typeof value === 'number') {
            const definition = positionAttributes[key] || {};
            switch (definition.dataType) {
              case 'speed':
                formatted[key] = speedFromKnots(value, speedUnit).toFixed(2);
                break;
              case 'altitude':
                formatted[key] = altitudeFromMeters(value, altitudeUnit).toFixed(2);
                break;
              case 'distance':
                formatted[key] = distanceFromMeters(value, distanceUnit).toFixed(2);
                break;
              case 'volume':
                formatted[key] = volumeFromLiters(value, volumeUnit).toFixed(2);
                break;
              case 'hours':
                formatted[key] = (value / 1000).toFixed(2);
                break;
              default:
                formatted[key] = value;
                break;
            }
          }
        });
        return formatted;
      });
      setItems(formattedPositions);
    } else {
      throw Error(await response.text());
    }
  });

  // Obtener la fecha actual
  const currentDate = new Date();

  // Crear el nombre del archivo utilizando el formato deseado
  const year = currentDate.getFullYear().toString().slice(-2);
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const fileName = `Grafica Pampasat (${year}-${month}-${day}).png`;

  const [getAreaPng, { ref: chartRef }] = useCurrentPng();

  const handleAreaDownload = useCallback(async () => {
    const png = await getAreaPng();
    if (png) {
      FileSaver.saveAs(png, fileName);
    }
  }, [getAreaPng]);

  const xAxisTickFormatter = useCallback((props) => {
    const { x, y, payload } = props;
    const { value } = payload;

    const formatTimeLabel = (time) => {
      const [date, timeValue] = time.split(' ');
      return (
        <>
          <tspan x="0" dy="0">{timeValue}</tspan>
          <tspan x="0" dy="15">{date}</tspan>
        </>
      );
    };

    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text x="0" y="0" dy="-5" textAnchor="middle" fill="#666">{formatTimeLabel(value)}</text>
      </g>
    );
  }, []);

  const handleResize = useCallback(() => {
    const windowWidth = window.innerWidth;
    let interval;

    if (windowWidth < 600) {
      interval = Math.ceil(items.length / 3);
    } else if (windowWidth < 960) {
      interval = Math.ceil(items.length / 5);
    } else {
      interval = Math.ceil(items.length / 7);
    }

    interval = Math.max(interval, 1);

    setXAxisInterval(interval);
  }, [items.length]);

  useEffect(() => {
    handleResize(); // Initial resize
    window.addEventListener('resize', handleResize); // Add event listener for resize
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener on unmount
    };
  }, [handleResize]);

  useEffect(() => {
    // Code for any additional side effects or lifecycle logic
    // that you want to add goes here
  }, []);

  const values = items.map((it) => it[type]);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const intMaxValue = Math.ceil(Math.max(Math.abs(maxValue), Math.abs(minValue), 0));
  const intMinValue = Math.floor(Math.min(Math.abs(maxValue), Math.abs(minValue), 0));

  const ticks = [];
  if (minValue >= 0) {
    ticks.push(intMaxValue);
    ticks.push(intMaxValue * 0.75);
    ticks.push(intMaxValue * 0.5);
    ticks.push(intMaxValue * 0.25);
  } else if (maxValue <= 0) {
    ticks.push(intMinValue * 0.25);
    ticks.push(intMinValue * 0.5);
    ticks.push(intMinValue * 0.75);
    ticks.push(intMinValue);
  } else {
    const positiveTick = intMaxValue > 0 ? intMaxValue * 0.25 : 0;
    const negativeTick = intMinValue < 0 ? intMinValue * 0.25 : 0;
    ticks.push(intMaxValue);
    ticks.push(positiveTick);
    ticks.push(negativeTick);
    ticks.push(intMinValue);
  }

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportChart']}>
      <ReportFilter handleSubmit={handleSubmit} showOnly esChart onClick2={() => handleAreaDownload()}>
        <div className={classes.filterItem}>
          <FormControl fullWidth>
            <InputLabel>{t('reportChartType')}</InputLabel>
            <Select label={t('reportChartType')} value={type} onChange={(e) => setType(e.target.value)}>
              {Object.keys(positionAttributes).filter((key) => positionAttributes[key].type === 'number').map((key) => (
                <MenuItem key={key} value={key}>{positionAttributes[key].name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </ReportFilter>
      {items.length > 0 && (
        <div className={classes.chart}>
          <ResponsiveContainer>
            <LineChart
              data={items}
              margin={{
                top: 50,
                right: 40,
                left: 0,
                bottom: 15, // Increase this value to create more space for X-axis labels
              }}
              ref={chartRef}
            >
              <XAxis dataKey="fixTime" interval={xAxisInterval} tick={xAxisTickFormatter} />
              <YAxis
                type="number"
                tickFormatter={(value) => value.toFixed(2)}
                domain={[minValue - 0.2, maxValue + ((minValue + maxValue) / 10)]}
                axisLine={false} // Hide the Y-axis line
                tickLine={false} // Hide the Y-axis tick lines
                ticks={ticks}
                tick={{ dy: 0 }} // Adjust the vertical position of the tick label
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip formatter={(value, key) => [value, positionAttributes[key].name]} />
              <Line type="monotone" dataKey={type} />
              <ReferenceLine
                y={maxValue}
                stroke="#f00"
                strokeDasharray="3 3"
                strokeWidth={2} // Adjust the line thickness
                label={{ position: 'top', offset: 10, value: `Max: ${maxValue.toFixed(2)}` }} // Position and label text
              />

            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </PageLayout>
  );
};

export default ChartReportPage;

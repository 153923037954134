import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import ReportFilter from './components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import useReportStyles from './common/useReportStyles';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import { formatTime } from '../common/util/formatter';
import { usePreference } from '../common/util/preferences';
import TableShimmer from '../common/components/TableShimmer';

const FlightHoursReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);

  const hours12 = usePreference('twelveHourFormat');

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const calculateFlightTime = (flightTimeInSeconds) => {
    if (flightTimeInSeconds >= 1) {
      const flightTimeInHours = Math.floor(flightTimeInSeconds / 3600);
      const flightTimeInMinutes = Math.floor((flightTimeInSeconds % 3600) / 60);
      if (flightTimeInMinutes < 4) {
        return 'menos de 0.1';
      }
      return (flightTimeInHours + flightTimeInMinutes / 60).toFixed(1);
    }
    return null;
  };

  const handleSubmit = useCatch(async ({ deviceIds, from, to }) => {
    setIsLoading(true);
    setItems([]);

    const query = new URLSearchParams({ from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));

    const response = await fetch(`/api/reports/route?${query.toString()}`);
    if (response.ok) {
      const data = await response.json();
      let lastIgnitionState = null;
      const ignitionEvents = {};
      data.forEach((position) => {
        if (position.attributes && 'ignition' in position.attributes) {
          const ignitionState = position.attributes.ignition;
          if (ignitionState !== lastIgnitionState) {
            if (!ignitionEvents[position.deviceId]) {
              ignitionEvents[position.deviceId] = [];
            }
            if (ignitionState) {
              ignitionEvents[position.deviceId].push({ masterOn: position.fixTime, masterOff: null, flightTime: null });
            } else if (ignitionEvents[position.deviceId].length > 0) {
              const lastEvent = ignitionEvents[position.deviceId][ignitionEvents[position.deviceId].length - 1];
              lastEvent.masterOff = position.fixTime;
              lastEvent.flightTime = new Date(lastEvent.masterOff) - new Date(lastEvent.masterOn);
            }
            lastIgnitionState = ignitionState;
          }
        }
      });
      const items = Object.entries(ignitionEvents).flatMap(([deviceId, events]) => events.map((event) => ({ deviceId, ...event })));
      setItems(items);
    } else {
      throw new Error(await response.text());
    }
    setIsLoading(false);
  });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportRoute']}>
      <div>
        <div className={classes.header}>
          <ReportFilter handleSubmit={handleSubmit} showOnly multiDevice />
        </div>
        {isLoading ? (
          <TableShimmer />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('sharedDevice')}</TableCell>
                <TableCell>Master ON</TableCell>
                <TableCell>Master OFF</TableCell>
                <TableCell>Hora de Vuelo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <TableRow key={index}>
                  <TableCell>{devices[item.deviceId]?.name}</TableCell>
                  <TableCell>{item.masterOn && formatTime(item.masterOn, 'seconds', hours12)}</TableCell>
                  <TableCell>{item.masterOff && formatTime(item.masterOff, 'seconds', hours12)}</TableCell>
                  <TableCell>{item.flightTime && calculateFlightTime(item.flightTime / 1000)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </PageLayout>
  );
};

export default FlightHoursReportPage;

import palette from '../../common/theme/palette';
import { loadImage, prepareIcon } from './mapUtil';

import directionSvg from '../../resources/images/direction.svg';
import backgroundSvg from '../../resources/images/background.svg';
import animalSvg from '../../resources/images/icon/animal.svg';
import bicycleSvg from '../../resources/images/icon/bicycle.svg';
import boatSvg from '../../resources/images/icon/boat.svg';
import busSvg from '../../resources/images/icon/bus.svg';
import carSvg from '../../resources/images/icon/car.svg';
import craneSvg from '../../resources/images/icon/crane.svg';
import defaultSvg from '../../resources/images/icon/default.svg';
import helicopterSvg from '../../resources/images/icon/helicopter.svg';
import motorcycleSvg from '../../resources/images/icon/motorcycle.svg';
import offroadSvg from '../../resources/images/icon/offroad.svg';
import personSvg from '../../resources/images/icon/person.svg';
import pickupSvg from '../../resources/images/icon/pickup.svg';
import planeSvg from '../../resources/images/icon/plane.svg';
import scooterSvg from '../../resources/images/icon/scooter.svg';
import shipSvg from '../../resources/images/icon/ship.svg';
import tractorSvg from '../../resources/images/icon/tractor.svg';
import trainSvg from '../../resources/images/icon/train.svg';
import tramSvg from '../../resources/images/icon/tram.svg';
import trolleybusSvg from '../../resources/images/icon/trolleybus.svg';
import truckSvg from '../../resources/images/icon/truck.svg';
import vanSvg from '../../resources/images/icon/van.svg';
import amarilloSvg from '../../resources/images/ring/ring_amarillo.svg';
import azulSvg from '../../resources/images/ring/ring_azul.svg';
import blancoSvg from '../../resources/images/ring/ring_blanco.svg';
import cyanSvg from '../../resources/images/ring/ring_cyan.svg';
import grisSvg from '../../resources/images/ring/ring_gris.svg';
import limaSvg from '../../resources/images/ring/ring_lima.svg';
import magentaSvg from '../../resources/images/ring/ring_magenta.svg';
import marronSvg from '../../resources/images/ring/ring_marron.svg';
import naranjaSvg from '../../resources/images/ring/ring_naranja.svg';
import negroSvg from '../../resources/images/ring/ring_negro.svg';
import olivaSvg from '../../resources/images/ring/ring_oliva.svg';
import rojoSvg from '../../resources/images/ring/ring_rojo.svg';
import rosaSvg from '../../resources/images/ring/ring_rosa.svg';
import verdeSvg from '../../resources/images/ring/ring_verde.svg';
import violetaSvg from '../../resources/images/ring/ring_violeta.svg';
import backgroundClusterSvg from '../../resources/images/background_cluster.svg';

export const mapIcons = {
  animal: animalSvg,
  bicycle: bicycleSvg,
  boat: boatSvg,
  bus: busSvg,
  car: carSvg,
  crane: craneSvg,
  default: defaultSvg,
  helicopter: helicopterSvg,
  motorcycle: motorcycleSvg,
  offroad: offroadSvg,
  person: personSvg,
  pickup: pickupSvg,
  plane: planeSvg,
  scooter: scooterSvg,
  ship: shipSvg,
  tractor: tractorSvg,
  train: trainSvg,
  tram: tramSvg,
  trolleybus: trolleybusSvg,
  truck: truckSvg,
  van: vanSvg,
  amarillo: amarilloSvg,
  azul: azulSvg,
  blanco: blancoSvg,
  cyan: cyanSvg,
  gris: grisSvg,
  lima: limaSvg,
  magenta: magentaSvg,
  marron: marronSvg,
  naranja: naranjaSvg,
  negro: negroSvg,
  oliva: olivaSvg,
  rojo: rojoSvg,
  rosa: rosaSvg,
  verde: verdeSvg,
  violeta: violetaSvg,
  backgroundCluster: backgroundClusterSvg,
};

export const mapIconKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'default');

export const mapImages = {};

export default async () => {
  const background = await loadImage(backgroundSvg);
  const amarillo = await loadImage(amarilloSvg);
  const azul = await loadImage(azulSvg);
  const blanco = await loadImage(blancoSvg);
  const cyan = await loadImage(cyanSvg);
  const gris = await loadImage(grisSvg);
  const lima = await loadImage(limaSvg);
  const magenta = await loadImage(magentaSvg);
  const marron = await loadImage(marronSvg);
  const naranja = await loadImage(naranjaSvg);
  const negro = await loadImage(negroSvg);
  const oliva = await loadImage(olivaSvg);
  const rojo = await loadImage(rojoSvg);
  const rosa = await loadImage(rosaSvg);
  const verde = await loadImage(verdeSvg);
  const violeta = await loadImage(violetaSvg);
  const backgroundCluster = await loadImage(backgroundClusterSvg);
  mapImages.background = await prepareIcon(background);
  mapImages.amarillo = await prepareIcon(amarillo);
  mapImages.azul = await prepareIcon(azul);
  mapImages.blanco = await prepareIcon(blanco);
  mapImages.cyan = await prepareIcon(cyan);
  mapImages.gris = await prepareIcon(gris);
  mapImages.lima = await prepareIcon(lima);
  mapImages.magenta = await prepareIcon(magenta);
  mapImages.marron = await prepareIcon(marron);
  mapImages.naranja = await prepareIcon(naranja);
  mapImages.negro = await prepareIcon(negro);
  mapImages.oliva = await prepareIcon(oliva);
  mapImages.rojo = await prepareIcon(rojo);
  mapImages.rosa = await prepareIcon(rosa);
  mapImages.verde = await prepareIcon(verde);
  mapImages.violeta = await prepareIcon(violeta);
  mapImages.backgroundCluster = await prepareIcon(backgroundCluster);
  mapImages.direction = await prepareIcon(await loadImage(directionSvg));
  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
    ['primary', 'positive', 'negative', 'neutral'].forEach((color) => {
      results.push(loadImage(mapIcons[category]).then((icon) => {
        mapImages[`${category}-${color}`] = prepareIcon(background, icon, palette().colors[color]);
      }));
    });
    await Promise.all(results);
  }));
};
